html.skyTheme,
body.skyTheme {
    background: linear-gradient(90deg, #BB6CFF 0%, #C088EB 25%, #B4A4FF 50%, #7BB0FF 75%, #7BBFFF 100%);
    color: #fff;
}

.skyTheme a { color: #DDD; }

.skyTheme [contentEditable=true]:empty:not(:focus):before { color: #DDD; }

.skyTheme .headerMain_pcverSB1 {
    background: linear-gradient(90deg, #A667DD 0%, #A762DD 25%, #937FF2 50%, #6DA1EE 75%, #4AA2F5 100%);
    color: #fff;
}

/* -- Header */
.skyTheme .headerMain_pcverSB1 > #search { background-color: #E0E0E040; }
.skyTheme .headerMain_pcverSB1 > #search .searchInput_sITPMVB1 { border-color: #E0E0E080; }

/* -- Navbar */
.skyTheme .navBar_pcverSB1 { background-color: #a875ca;border-color: #E0E0E040;color: #fff; }
.skyTheme .actions_bNBmVeB1 a { color: #fff; }

/* -- Live Search */
.skyTheme #liveSearch { background: linear-gradient(120deg, #cf9fff 0%, #768AD7); }

.skyTheme #liveSearch .profileLiveSearch_lSearchMVB1:hover { background: #0000001A; }
.skyTheme #liveSearch .profileLiveSearch_lSearchMVB1 #usertag { color: #ddd; }
.skyTheme a > .noResults_lSearchMVB1 { color: #FFFFFFBF; }

/* -- Right Menu */
.skyTheme .trendMenu_pcverSB1 { background-color: #79838A59;color: #fff; }
.skyTheme .trendContainer_mDrawerMVB1 { background-color: #E0E0E01A;border-color: #E0E0E040; }
.skyTheme .stats_tCMVB1 { color: #DDD; }
.skyTheme .accountPre_sRlBPcVB1 { border-color: #E0E0E040; }
.skyTheme .accountPre_sRlBPcVB1:not([id="no-hover"]):hover { background-color: #E0E0E040; }
.skyTheme .accountPre_sRlBPcVB1 > #profile > #user #usertag { color: #DDD; }
.skyTheme .suggestions_rlBPcVB1 > #refresh { color: #DDD; }
.skyTheme .legalMentions_tMpVB1, .skyTheme .legalMentions_tMpVB1 a { color: #DDD; }

.skyTheme .trendMenu_pcverSB1 #hide-show .background { fill: #73A7F4; }
.skyTheme .trendMenu_pcverSB1 #hide-show .arrow { fill: #A7C3EE; }

.skyTheme .trendMenu_pcverSB1 .hideShow-category { color: #DDD; }

/* -- Left Menu */
.skyTheme .topMenu_pcverSB1 { background-color: #a875ca;color: #fff; }
.skyTheme .others_fBmVeB1 div:hover:not([class="newChat-privateMsgPcVB1"]) { background-color: #E0E0E01A; }
.skyTheme .others_fBmVeB1 div.current { background-color: #E0E0E01A; }
.skyTheme .others_fBmVeB1 #for-you .gradient { color: #fff;background-color: transparent; }
.skyTheme .others_fBmVeB1 #for-you:not([class="current"]):hover .gradient-in { background-color: transparent;color: #fff; }
/* -- -- Filters Part */
.skyTheme .filter_fBmVeB1 div.current { background-color: #E0E0E01A; }
.skyTheme .filter_fBmVeB1 div.current:hover { background-color: #E0E0E01A; }
.skyTheme .filter_fBmVeB1 div:not([class="foo-menu-social"]):hover { background-color: #E0E0E01A; }

/* -- Separator */
.skyTheme .separator_fBmVeB1, .skyTheme .separator_sMAMVeB1 { border-color: #E0E0E040; }

/* -- Home */
.skyTheme .mainPart_mAppMVB1 { background: linear-gradient(90deg, #8A798A4D 0%, #79838A4D 100%); }

/* -- Posts */
.skyTheme .spost_mAppMVeB1 { border-color: #E0E0E040; }
.skyTheme .mediaSpost { border: #E0E0E040; }
.skyTheme .countInteractions_spostMAppMVB1 { background-color: #E0E0E040; }
.skyTheme .countInteractions_spostMAppMVB1 #separator { border-color: #E0E0E080; }
.skyTheme .countInteractions_spostMAppMVB1 div:not([id="separator"]):hover { background-color: #E0E0E040; }

.skyTheme .spost_sMAMVeB1 { background-color: #E0E0E040;color: #fff; }
.skyTheme .topPart_sMainMVB1, .skyTheme .spostContent_sMainMVB1 { background: linear-gradient(90deg, #96639640 0%, #689bc040 100%); }
.skyTheme .countInteractions_sMAMVeB1 { background-color: #E0E0E040; }
.skyTheme .countInteractions_sMAMVeB1 #separator { border-color: #E0E0E080; }
.skyTheme .countInteractions_sMAMVeB1 div:not([id="separator"]):hover { background-color: #E0E0E040; }
.skyTheme .moreInteractions_sMAMVeB1 { background-color: #E0E0E040; }

.skyTheme .profilePart_sMainMVB1 #usertag,
.darkTheme .datePart_sMainMVB1,
.skyTheme .profilePart_spostMAppMVB1 #usertag,
.skyTheme .datePart_spostMAppMVB1 { color: #DDD; }

.skyTheme .tags_sMAMVeB1 div { background-color: #E0E0E080;color: #fff; }

/* -- Comments */
.skyTheme .addComBtn_sMAMVeB1 { background-color: #E0E0E040;border-color: #E0E0E080; }

.skyTheme .countInteractions_comMAMVeB1 { background-color: #E0E0E040; }
.skyTheme .countInteractions_comMAMVeB1 #separator { border-color: #E0E0E080; }
.skyTheme .countInteractions_comMAMVeB1 div:not([id="separator"]):hover { background-color: #E0E0E040; }

/* -- Settings */
.skyTheme .saveBtnContainer_setMain-pcVB1 { background-color: #E0E0E0BF;color: #000; }
.skyTheme .settingsPart_setMainMVB1 #AccountSettings div input { background-color: #E0E0E0BF;color: #000; }
.skyTheme .updateBtn_tPSAMVB1 { background-color: #202020;color: #fff; }
.skyTheme .settingsPart_setMainMVB1 #AccountSettings > div:not([class="topSection_pswdSet-accSetPcVB1"]) { border-color: #E0E0E080; }
.skyTheme .topPart_setAccMVB1 > #subtitle { color: #ddd; }

.skyTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > div { background-color: #E8E8E8;color: #000; }
.skyTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > #goBack:hover { background-color: #E8E8E8;border-color: #E8E8E8; }

/* -- -- Account */
.skyTheme .updatePswd_accSetPcVB1,
.skyTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1 { background-color: #E0E0E0BF;color: #000; }

.skyTheme .updatePswd_accSetPcVB1:hover,
.skyTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1:hover { background-color: #FFF; }

.skyTheme .settingsPart_setMainMVB1 #AccountSettings div .warning_2faSetMVB1 { background-color: #E0E0E0BF;color: #000; }

/* -- -- Privacy */
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .demandDataBtn_privacySetMVB1,
.skyTheme .settingsPart_setMainMVB1 #AccountSettings .selectBox_visSetMVB1 { background-color: #E0E0E0BF;color: #000; }

.skyTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .seeOurPolicies { background-color: #E0E0E040; }

/* -- -- Safety */
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.privacy div #noteInfo { color: #ddd; }
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 #filters div { background-color: #E0E0E0BF;color: #000; }

.skyTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label { background-color: #5d84bf; }
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label::before { background-color: #83adec; }

.skyTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label { background-color: #a5cafb; }
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label::before { background-color: #c6daf7; }

/* -- -- Sessions */
.skyTheme .sessionContainer_currentSetPcVB1,
.skyTheme .sessionContainer_othersSetPcVB1 { background-color: #E0E0E0BF;color: #000; }

.skyTheme .sessionContainer_currentSetPcVB1 > .infosDevice #location,
.skyTheme .sessionContainer_othersSetPcVB1 > .infosDevice #location { color: #707070; }
.skyTheme .sessionContainer_currentSetPcVB1 > i,
.skyTheme .sessionContainer_othersSetPcVB1 > i { color: #303030; }

.skyTheme .sessionContainer_othersSetPcVB1 > .closeConnection { color: #000; }
.skyTheme .sessionContainer_othersSetPcVB1 > .closeConnection > i:hover { color: #505050; }

/* -- -- Subscriptions / Bills */
.skyTheme .arraySubscription_subSetPcVB1 { background-color: #E0E0E056; }

.skyTheme .item_arrayPcVB1 #mainInfos { border-color: #E0E0E0BF; }
.skyTheme .item_arrayPcVB1 #mainInfos:hover { background-color: #E0E0E056; }

.skyTheme .item_arrayPcVB1 i { color: #DDD; }

.skyTheme .item_arrayPcVB1 #details { background-color: #E0E0E056; }

/* -- -- Appearance */
.skyTheme .settingsPart_setMainMVB1 #AccountSettings div > h2 { color: #FFFFFFBF; }
.skyTheme .exclusThemes_appSetMVB1 #theme,
.skyTheme .settingsPart_setMainMVB1 #AccountSettings.appearance .themeContainer_appSetMVB1 #theme { border-color: #bbb; }
.skyTheme #theme.selected { border-color: #fff; }
.skyTheme .themeSelected { background-color: #fff;color: #000; }
.skyTheme .exclusThemes_appSetMVB1 { border-color: #E0E0E080; }
.skyTheme .newInfoTag_exclusTPcVB1 { background: #FFF;color: #000; }

/* -- -- Language */
.skyTheme .settingsPart_setMainMVB1 #LanguageSettings div #languageChoice { background-color: #E0E0E0BF; }
.skyTheme .settingsPart_setMainMVB1 #LanguageSettings div { border-color: #E0E0E080; }

/* -- Friends */
.skyTheme .userContainer_friendsMMVB1 { background-color: #E0E0E040; }
.skyTheme .userContainer_friendsMMVB1 #profilePart .usertag #usertag { color: #DDD; }
.skyTheme .userContainer_friendsMMVB1 #actionsButtons div { background-color: #67a4ff80; }

.skyTheme .privateMsg_fBmVeB1 div.current { background-color: #E0E0E01A; }
.skyTheme .privateMsg_fBmVeB1 > div:hover { background-color: #E0E0E01A; }
.skyTheme #userLatest .avatar img { background-color: #E0E0E080; }

.skyTheme .topPart_friendsMMVB1 .groupButton::before { background: none; }
.skyTheme .topPart_friendsMMVB1 .groupButton { background-color: #E0E0E059;border: 3px solid #E0E0E099; }

/* -- Profile */
.skyTheme #profilePart_Profile { border-color: #E0E0E040; }
.skyTheme .spostPart_profileMVB1 { border-color: #E0E0E040; }

.skyTheme .profileInfo_tPProfileMVB1 #usertag,
.skyTheme .createDate_tPProfileMVB1 { color: #ddd; }
.skyTheme .aboutMe_tPProfileMVB1,
.skyTheme .topPart_profileMVB1 > #actionsButtons > div,
.skyTheme .editProfileBtn_tPProfileMVB1 { background-color: #E0E0E040; }

.skyTheme #followCountProfile > div { background-color: #E0E0E040;border-color: #E0E0E080; }
.skyTheme .followersCount_profileMVB1 .separator_fCPMVB1 { border-color: #E0E0E080; }

.skyTheme .linksProfile_tPProfileMVB1 #separator_lProfileMVB1 { border-color: #DDDDDD80; }

/* -- Add */
.skyTheme .titlePage_aSPMVB1 > h2 { color: #DDD; }

.skyTheme .searchTagSpost_aSPMVB1 > input::placeholder,
.skyTheme .textSpost_aSPMVB1 > [contentEditable="true"]:empty:not(:focus)::before,
.skyTheme .titleSpost_aSPMVB1 > input::placeholder { color: #FFFFFFBF; }

.skyTheme .textSpost_aSPMVB1 > div[contentEditable="true"],
.skyTheme .mediaSpost_aSPMVB1 .chooseFile_mSMVB1,
.skyTheme .searchTagSpost_aSPMVB1 > input, 
.skyTheme .titleSpost_aSPMVB1 > input {
    color: #FFF;
    background-color: #E0E0E056;
    border-color: #E0E0E0BF;
}

.skyTheme .TextareaProtectValues { background-color: transparent; }
.skyTheme .emojiPicker_textSpost {
    background-color: #E0E0E056;
    color: #FFFFFFBF;
}

.skyTheme .tagsSpost_aSPMVB1 div {
    background-color: #E0E0E080;
    border-color: #E0E0E0BF;
    color: #fff;
}
.skyTheme .tagsSpost_aSPMVB1 div:hover,
.skyTheme .tagsSpost_aSPMVB1 div.selected {
    background-color: #E0E0E0BF;
    border-color: #E0E0E0;
    color: #505050;
}

.skyTheme .separator_addSpost { border-color: #E0E0E040; }

.skyTheme .options_aSPPcVB1 {
    background-color: #E0E0E056;
    backdrop-filter: blur(7px);
}
.skyTheme .options_aSPPcVB1 #betaContainer { background: #fff;color: #000; }

.skyTheme .options_aSPPcVB1 input + label { background-color: #5d84bf; }
.skyTheme .options_aSPPcVB1 input + label::before { background-color: #83adec; }

.skyTheme .options_aSPPcVB1 input:checked + label { background-color: #bfdbff; }
.skyTheme .options_aSPPcVB1 input:checked + label::before { background-color: #dae9ff; }

.skyTheme .buttonsAction_aSPMVB1 div { color: #000; }
.skyTheme .buttonsAction_aSPMVB1 #infoPosting { color: #DDD; }
.skyTheme .buttonsAction_aSPMVB1 #infoPosting a { color: #FFF; }

/* -- Menu */
.skyTheme .menu-wrapper {
    background: #BAD6FF;
    border-color: #D2E4FF;
    color: #2B3340;
}
.skyTheme .actionsPart_spostMMVB1 div:hover,
.skyTheme .actionsPart_moreFriendsMMVB1 div:hover  { background-color: #a2c5fa; }

.skyTheme #settingsMenu .menu-wrapper,
.skyTheme #logoutMenu .menu-wrapper { background-color: #a761c8;border-color: #c991e3; }
.skyTheme .settings_menuMVB1 > a { color: #FFD7FF; }
.skyTheme .settings_menuMVB1 > a:hover { background: #a34ba3; }

.skyTheme #logoutMenu .menu-wrapper { color: #fff; }

.skyTheme .topPart_notifsContainerMVB1 { border-color: #D2E4FF; }

/* -- Chat */
.skyTheme .bottomBarActions_chatFMVB1 {
    border-color: #DDDDDD60;
    background: linear-gradient(120deg, #cf9fff 0%, #768AD7);
    margin-left: -15px;
    width: calc(100% - 335px);
    padding-left: 5px;
}
.skyTheme .bottomBarActions_chatFMVB1 div { background-color: #DDDDDD40; }
.skyTheme .bottomBarActions_chatFMVB1 div:not(.textArea_bBAChatMVB1):hover { background-color: #EEEEEE50; }

.skyTheme .message_pChatFMMVB1 { border-color: #DDDDDD40; }
.skyTheme .message_pChatFMMVB1:hover { background-color: #DDDDDD30; }
.skyTheme .profilePart_msgPCMMVB1 #usertag,
.skyTheme .datePart_msgPCMMVB1 { color: #ddd; }
.skyTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div { background-color: #7faad380; }
.skyTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div:hover { background-color: #7f9cd3; }

.skyTheme .editReply_chatMMVB1,
.skyTheme .typingIndicator_chatMMVB1 {
    background: linear-gradient(120deg, #cf9fff 0%, #768AD7);
    border-color: #DDDDDD40;
}

.skyTheme .replyContainer_msgPCMMVB1 { background: linear-gradient(40deg, #d67edd 0%, #7f70ea 100%); }

/* -- New modals colors */
.skyTheme .modal .newColors, .skyTheme #createGroup .newColors { background-color: #8294af; }

/* -- Modals */
.skyTheme .topPart_fSLMPcB1 h1 { color: #fff; }
.skyTheme .actionsButtons_adventPcVB1 > div { background-color: #9eb0cc; }

/* -- -- Group */
.skyTheme .topPart_groupPcVB1 > h1 { color: #fff; }
.skyTheme .topPart_groupPcVB1 > h2 { color: #ddd; }

.skyTheme .addPicture_gPcVB1 {
    border-color: #DDDDDD80;
    color: #DDDDDD80;
}
.skyTheme .addPicture_gPcVB1:hover {
    border-color: #DDDDDD40;
    color: #DDDDDD40;
}

.skyTheme .addPicture_gPcVB1 #addIcon { background: #fff;color: #707070; }

.skyTheme .groupName_gPcVB1 > input {
    background: #DDDDDD10;
    border-color: #DDDDDD40;
    color: #fff;
}

.skyTheme .actions_gPcVB1 > div { background-color: #87a5d1; }
.skyTheme .actions_gPcVB1 > div:hover { background-color: #a2c5fa; }
.skyTheme .actions_gPcVB1 > #create::before { background: #a2c5fa; }
.skyTheme .actions_gPcVB1 > #cancel, .skyTheme .actions_gPcVB1 > #go-back { border-color: #a2c5fa; }

.skyTheme .searchFriend_cGrPcVB1 {
    background-color: #8b9ebb;
    border-color: #a3b8d8;
}
.skyTheme .searchFriend_cGrPcVB1 > i { color: #DDD; }

.skyTheme .friend_cGrPcVB1:hover,
.skyTheme .friend_cGrPcVB1.selected { background-color: #DDDDDD25; }
.skyTheme .friend_cGrPcVB1 .usertag { color: #DDD; }
.skyTheme .stickyBottom_aGPcVB1 { background-color: #8294af;border-color: #a3b8d8; }

.skyTheme .topPart_friendsMMVB1 #groupSettingsBtn { background-color: #b784ca;border-color: #e0bbee; }
.skyTheme .topPart_friendsMMVB1 #membersList img { outline-color: transparent; }

.skyTheme .note_gPcVB1 { color: #ddd; }

/* -- RED */
.skyTheme .actionsPart_spostMMVB1 #delete, .skyTheme .actionsPart_spostMMVB1 #report,
.skyTheme .actionsPart_moreFriendsMMVB1 #remove, .skyTheme .actionsPart_moreFriendsMMVB1 #block  { color: #C11111; }

.skyTheme .streakInfo_containerMVB1 { background: linear-gradient(320deg,#d2c0e1,#d49dff 25%,#c6baff 50%,#9cbae8 75%,#cfe8ff); }

/* -- Loading */
.skyTheme .skeleton {
    background: linear-gradient(to right, #E0E0E01A 8%, #E0E0E040 18%, #E0E0E059 33%);
    background-size: 1200px 100%;
}

/* -----------
 * ABYSS THEME
 * -----------
 */
html.abyssTheme,
body.abyssTheme {
    background: linear-gradient(90deg,#E83131  0%,#3B0202  100%);
    color: #fff;
}

.abyssTheme a { color: #DDD; }

.abyssTheme [contentEditable=true]:empty:not(:focus):before { color: #DDD; }

.abyssTheme .headerMain_pcverSB1 {
    background: linear-gradient(90deg, #B92C2C 0%, #430909 100%);
    color: #fff;
}

/* -- Header */
.abyssTheme .headerMain_pcverSB1 > #search { background-color: #C1787840; }
.abyssTheme .headerMain_pcverSB1 > #search .searchInput_sITPMVB1 { border-color: #B66D6D40; }

/* -- Navbar */
.abyssTheme .navBar_pcverSB1 { background-color: #883030;border-color: #B66D6D80;color: #fff; }
.abyssTheme .actions_bNBmVeB1 a { color: #fff; }

/* -- Live Search */
.abyssTheme #liveSearch { background: linear-gradient(120deg, #B92C2C 0%, #430909); }

.abyssTheme #liveSearch .profileLiveSearch_lSearchMVB1:hover { background: #DE8C8C40; }
.abyssTheme #liveSearch .profileLiveSearch_lSearchMVB1 #usertag { color: #ddc3c3; }
.abyssTheme a > .noResults_lSearchMVB1 { color: #ddc3c3; }

/* -- Right Menu */
.abyssTheme .trendMenu_pcverSB1 { background-color: #43090980;color: #fff; }
.abyssTheme .trendContainer_mDrawerMVB1 { background-color: #592626BF;border-color: #6F454580; }
.abyssTheme .stats_tCMVB1 { color: #B89B9B; }
.abyssTheme .accountPre_sRlBPcVB1 { border-color: #6F4545BF; }
.abyssTheme .accountPre_sRlBPcVB1:not([id="no-hover"]):hover { background-color: #FFFFFF1A; }
.abyssTheme .accountPre_sRlBPcVB1 > #profile > #user #usertag { color: #B89B9B; }
.abyssTheme .suggestions_rlBPcVB1 > #refresh { color: #D18989; }
.abyssTheme .legalMentions_tMpVB1, .abyssTheme .legalMentions_tMpVB1 a { color: #B89B9B; }

.abyssTheme .trendMenu_pcverSB1 #hide-show .background { fill: #40131380; }
.abyssTheme .trendMenu_pcverSB1 #hide-show .arrow { fill: #661C1C; }

.abyssTheme .trendMenu_pcverSB1 .hideShow-category { color: #DDD; }

/* -- Left Menu */
.abyssTheme .topMenu_pcverSB1 { background-color: #883030;color: #fff; }
.abyssTheme .others_fBmVeB1 div:hover:not([class="newChat-privateMsgPcVB1"]) { background-color: #B66D6D40; }
.abyssTheme .others_fBmVeB1 div.current { background-color: #B66D6D40; }
.abyssTheme .others_fBmVeB1 #for-you .gradient { color: #fff;background-color: transparent; }
.abyssTheme .others_fBmVeB1 #for-you:not([class="current"]):hover .gradient-in { background-color: transparent;color: #fff; }
/* -- -- Filters Part */
.abyssTheme .filter_fBmVeB1 div.current { background-color: #B66D6D40; }
.abyssTheme .filter_fBmVeB1 div.current:hover { background-color: #B66D6D40; }
.abyssTheme .filter_fBmVeB1 div:not([class="foo-menu-social"]):hover { background-color: #B66D6D40; }

/* -- Separator */
.abyssTheme .separator_fBmVeB1, .abyssTheme .separator_sMAMVeB1 { border-color: #6F454580; }
.abyssTheme .topMenu_pcverSB1 .separator_fBmVeB1 { border-color: #B66D6D80; }
.abyssTheme .linksProfile_tPProfileMVB1 #separator_lProfileMVB1 { border-color: #6F454580; }

/* -- Home */
.abyssTheme .mainPart_mAppMVB1 { background-color: #60212180; }

/* -- Posts */
.abyssTheme .spost_mAppMVeB1 { border-color: #8F585880; }
.abyssTheme .mediaSpost { border: #8A555580; }
.abyssTheme .countInteractions_spostMAppMVB1 { background-color: #882C2C80; }
.abyssTheme .countInteractions_spostMAppMVB1 #separator { border-color: #AE6363BF; }
.abyssTheme .countInteractions_spostMAppMVB1 div:not([id="separator"]):hover { background-color: #B66D6D40; }

.abyssTheme .spost_sMAMVeB1 { background-color: #60212180;color: #fff; }
.abyssTheme .topPart_sMainMVB1, .abyssTheme .spostContent_sMainMVB1 { background: linear-gradient(90deg, #a12121 0%, #7b1919 100%); }
.abyssTheme .countInteractions_sMAMVeB1 { background-color: #882C2C80; }
.abyssTheme .countInteractions_sMAMVeB1 #separator { border-color: #AE6363BF; }
.abyssTheme .countInteractions_sMAMVeB1 div:not([id="separator"]):hover { background-color: #B66D6D40 ; }
.abyssTheme .moreInteractions_sMAMVeB1 { background-color: #90262666; }

.abyssTheme .profilePart_sMainMVB1 #usertag,
.abyssTheme .datePart_sMainMVB1,
.abyssTheme .profilePart_spostMAppMVB1 #usertag,
.abyssTheme .datePart_spostMAppMVB1 { color: #B89B9B; }

.abyssTheme .tags_sMAMVeB1 div { background-color: #ffaeae40;color: #fff; }

/* -- Comments */
.abyssTheme .addComBtn_sMAMVeB1 { background-color: #60212180;border-color: #8F585880; }

.abyssTheme .countInteractions_comMAMVeB1 { background-color: #882C2C80; }
.abyssTheme .countInteractions_comMAMVeB1 #separator { border-color: #AE6363BF; }
.abyssTheme .countInteractions_comMAMVeB1 div:not([id="separator"]):hover { background-color: #B66D6D40; }

/* -- Settings */
.abyssTheme .saveBtnContainer_setMain-pcVB1 { background-color: #ffffff5b;color: #000; }
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings div input { background-color: #ffffff77;color: #000; }
.abyssTheme .updateBtn_tPSAMVB1 { background-color: #000;color: #fff; }
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings > div:not([class="topSection_pswdSet-accSetPcVB1"]) { border-color: #b1636380; }
.abyssTheme .topPart_setAccMVB1 > #subtitle { color: #ddd; }

.abyssTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > div { background-color: #fff;color: #000; }
.abyssTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > #goBack:hover { background-color: #E8E8E8;border-color: #E8E8E8; }

/* -- -- Account */
.abyssTheme .updatePswd_accSetPcVB1,
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1 { background-color: #ffffff92;color: #000; }

.abyssTheme .updatePswd_accSetPcVB1:hover,
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1:hover { background-color: #FFF; }

.abyssTheme .settingsPart_setMainMVB1 #AccountSettings div .warning_2faSetMVB1 { background-color: #ffffff92;color: #000; }

/* -- -- Privacy */
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .demandDataBtn_privacySetMVB1,
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings .selectBox_visSetMVB1 { background-color: #ffffff92;color: #000; }

.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .seeOurPolicies { background-color: #E0E0E040; }

/* -- -- Safety */
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.privacy div #noteInfo { color: #ddd; }
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 #filters div { background-color: #ffffff92;color: #000; }

.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label { background-color: #7b2f2f; }
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label::before { background-color: #5B0A0A; }

.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label { background-color: #8e2727; }
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label::before { background-color: #B33B3B; }

/* -- -- Sessions */
.abyssTheme .sessionContainer_currentSetPcVB1,
.abyssTheme .sessionContainer_othersSetPcVB1 { background-color: #e0e0e096;color: #000; }

.abyssTheme .sessionContainer_currentSetPcVB1 > .infosDevice #location,
.abyssTheme .sessionContainer_othersSetPcVB1 > .infosDevice #location { color: #3a3a3a; }
.abyssTheme .sessionContainer_currentSetPcVB1 > i,
.abyssTheme .sessionContainer_othersSetPcVB1 > i { color: #3e3e3e; }

.abyssTheme .sessionContainer_othersSetPcVB1 > .closeConnection { color: #000; }
.abyssTheme .sessionContainer_othersSetPcVB1 > .closeConnection > i:hover { color: #505050; }

/* -- -- Subscriptions / Bills */
.abyssTheme .arraySubscription_subSetPcVB1 { background-color: #E0E0E056; }

.abyssTheme .item_arrayPcVB1 #mainInfos { border-color: #E0E0E0BF; }
.abyssTheme .item_arrayPcVB1 #mainInfos:hover { background-color: #E0E0E056; }

.abyssTheme .item_arrayPcVB1 i { color: #DDD; }

.abyssTheme .item_arrayPcVB1 #details { background-color: #E0E0E056; }

/* -- -- Appearance */
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings div > h2 { color: #ffffff92; }
.abyssTheme .exclusThemes_appSetMVB1 #theme,
.abyssTheme .settingsPart_setMainMVB1 #AccountSettings.appearance .themeContainer_appSetMVB1 #theme { border-color: #976a6a; }
.abyssTheme #theme.selected { border-color: #fff; }
.abyssTheme .themeSelected { background-color: #fff;color: #000; }
.abyssTheme .exclusThemes_appSetMVB1 { border-color: #E0E0E080; }
.abyssTheme .newInfoTag_exclusTPcVB1 { background: #FFF;color: #000; }

/* -- -- Language */
.abyssTheme .settingsPart_setMainMVB1 #LanguageSettings div #languageChoice { background-color: #ffffff7d; }
.abyssTheme .settingsPart_setMainMVB1 #LanguageSettings div { border-color: #E0E0E080; }

/* -- Friends */
.abyssTheme .topPart_friendsMMVB1 { border-color: #cd818180; }

.abyssTheme .userContainer_friendsMMVB1 { background-color: #cacaca41; }
.abyssTheme .userContainer_friendsMMVB1 #profilePart .usertag #usertag { color: #DDD; }
.abyssTheme .userContainer_friendsMMVB1 #actionsButtons div { background-color: #3B020280; }

.abyssTheme .privateMsg_fBmVeB1 div.current { background-color: #FFFFFF1A; }
.abyssTheme .privateMsg_fBmVeB1 > div:hover { background-color: #E0E0E01A; }
.abyssTheme #userLatest .avatar img { background-color: #E0E0E080; }

.abyssTheme .topPart_friendsMMVB1 .groupButton::before { background: none; }
.abyssTheme .topPart_friendsMMVB1 .groupButton { background-color: #493b3b80;border: 3px solid #cd818180; }

/* -- Profile */
.abyssTheme #profilePart_Profile { border-color: #8f5858dc }
.abyssTheme .spostPart_profileMVB1 { border-color: #8f5858dc; }

.abyssTheme .profileInfo_tPProfileMVB1 #usertag,
.abyssTheme .createDate_tPProfileMVB1 { color: #B89B9B; }
.abyssTheme .aboutMe_tPProfileMVB1,
.abyssTheme .topPart_profileMVB1 > #actionsButtons > div,
.abyssTheme .editProfileBtn_tPProfileMVB1 { background-color: #60212191; }

.abyssTheme #followCountProfile > div { background-color: #882C2C80;border-color: #ae6363bf; }
.abyssTheme .followersCount_profileMVB1 .separator_fCPMVB1 { border-color: #ae6363bf; }

/* -- Add */
.abyssTheme .titlePage_aSPMVB1 > h2 { color: #dfc5c5; }

.abyssTheme .searchTagSpost_aSPMVB1 > input::placeholder,
.abyssTheme .textSpost_aSPMVB1 > [contentEditable="true"]:empty:not(:focus)::before,
.abyssTheme .titleSpost_aSPMVB1 > input::placeholder { color: #FFFFFF80; }

.abyssTheme .textSpost_aSPMVB1 > div[contentEditable="true"],
.abyssTheme .mediaSpost_aSPMVB1 .chooseFile_mSMVB1,
.abyssTheme .searchTagSpost_aSPMVB1 > input,
.abyssTheme .titleSpost_aSPMVB1 > input {
    color: #ffffffca;
    background-color: #60212180;
    border-color: #8F585880;
}

.abyssTheme .TextareaProtectValues { background-color: transparent; }
.abyssTheme .emojiPicker_textSpost {
    background-color: #F0F0F040;
    color: #F0F0F0BF;
}

.abyssTheme .tagsSpost_aSPMVB1 div {
    background-color: #882C2C80;
    border-color: #8F585880;
    color: #fff;
}
.abyssTheme .tagsSpost_aSPMVB1 div:hover,
.abyssTheme .tagsSpost_aSPMVB1 div.selected {
    background-color: #a94c4c80;
    border-color: #94686880;
    color: #fff;
}

.abyssTheme .separator_addSpost { border-color: #8F585880; }

.abyssTheme .options_aSPPcVB1 {
    background-color: #60212180;
    backdrop-filter: blur(7px);
}
.abyssTheme .options_aSPPcVB1 #betaContainer { background: #fff;color: #000; }

.abyssTheme .options_aSPPcVB1 input + label { background-color: #7b2f2f; }
.abyssTheme .options_aSPPcVB1 input + label::before { background-color: #5B0A0A; }

.abyssTheme .options_aSPPcVB1 input:checked + label { background-color: #8e2727; }
.abyssTheme .options_aSPPcVB1 input:checked + label::before { background-color: #B33B3B; }

.abyssTheme .buttonsAction_aSPMVB1 div { color: #000; }
.abyssTheme .buttonsAction_aSPMVB1 #infoPosting { color: #DDD; }
.abyssTheme .buttonsAction_aSPMVB1 #infoPosting a { color: #FFF; }

/* -- Menu */
.abyssTheme .menu-wrapper {
    background: #8d2525;
    border-color: #C47373;
    color: #FFCDCD;
}
.abyssTheme .actionsPart_spostMMVB1 div:hover,
.abyssTheme .actionsPart_moreFriendsMMVB1 div:hover,
.abyssTheme .actionsPart_actionsMenuMMVB1 div:hover { background-color: #a24242; }

.abyssTheme #settingsMenu .menu-wrapper,
.abyssTheme #logoutMenu .menu-wrapper { background-color: #882C2C;border-color: #8F5858; }
.abyssTheme .settings_menuMVB1 > a { color: #FFCDCD; }
.abyssTheme .settings_menuMVB1 > a:hover { background: #a24242; }

.abyssTheme #logoutMenu .menu-wrapper { color: #fff; }

.abyssTheme .topPart_notifsContainerMVB1 { border-color: #C47373; }

.abyssTheme .confirmationLogout_menuMVB1 #title,
.abyssTheme .confirmationLogout_menuMVB1 #warn .ask { color: #fff; }
.abyssTheme .confirmationLogout_menuMVB1 #warn { color: #FFCDCD; }

/* -- Chat */
.abyssTheme .bottomBarActions_chatFMVB1 {
    border-color: #DDDDDD40;
    background: linear-gradient(120deg, #9e1f1f 0%, #360404);
    margin-left: -15px;
    width: calc(100% - 335px);
    padding-left: 5px;
}
.abyssTheme .bottomBarActions_chatFMVB1 div { background-color: #DDDDDD30; }

.abyssTheme .message_pChatFMMVB1 { border-color: #DDDDDD40; }
.abyssTheme .message_pChatFMMVB1:hover { background-color: #DDDDDD30; }
.abyssTheme .profilePart_msgPCMMVB1 #usertag,
.abyssTheme .datePart_msgPCMMVB1 { color: #ddd; }
.abyssTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div { background-color: #3B020280; }
.abyssTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div:hover { background-color: #6b2121; }

.abyssTheme .editReply_chatMMVB1,
.abyssTheme .typingIndicator_chatMMVB1 {
    background: linear-gradient(120deg, #9e1f1f 0%, #360404);
    border-color: #DDDDDD40;
}

.abyssTheme .replyContainer_msgPCMMVB1 { background: linear-gradient(40deg, #ce6363 0%, #c84545 100%); }

/* -- RED */
.abyssTheme .actionsPart_spostMMVB1 #delete, .abyssTheme .actionsPart_spostMMVB1 #report,
.abyssTheme .actionsPart_moreFriendsMMVB1 #remove, .abyssTheme .actionsPart_moreFriendsMMVB1 #block  { color: #ffd6d6; }

.abyssTheme .streakInfo_containerMVB1 { background: linear-gradient(70deg, #841F2A 0%, #B01B2A 50%, #B33845 75%, #62121B 100%); }

/* -- New modals colors */
.abyssTheme .modal .newColors, .abyssTheme #createGroup .newColors { background-color: #5b2828; }

/* -- Modals */
.abyssTheme .topPart_fSLMPcB1 h1 { color: #fff; }
.abyssTheme .actionsButtons_adventPcVB1 > div { background-color: #994c4c; }

/* -- -- Group */
.abyssTheme .topPart_groupPcVB1 > h1 { color: #fff; }
.abyssTheme .topPart_groupPcVB1 > h2 { color: #CCACAC; }

.abyssTheme .addPicture_gPcVB1 {
    border-color: #DDDDDD80;
    color: #DDDDDD80;
}
.abyssTheme .addPicture_gPcVB1:hover {
    border-color: #DDDDDD40;
    color: #DDDDDD40;
}

.abyssTheme .addPicture_gPcVB1 #addIcon { background: #994c4c; }

.abyssTheme .groupName_gPcVB1 > input {
    background: #DDDDDD20;
    border-color: #DDDDDD40;
    color: #fff;
}

.abyssTheme .actions_gPcVB1 > div { background-color: #955151; }
.abyssTheme .actions_gPcVB1 > div:hover { background-color: #863636; }
.abyssTheme .actions_gPcVB1 > #create::before { background: #C47373; }
.abyssTheme .actions_gPcVB1 > #cancel, .abyssTheme .actions_gPcVB1 > #go-back { border-color: #955151; }

.abyssTheme .searchFriend_cGrPcVB1 {
    background-color: #754242;
    border-color: #754242;
}
.abyssTheme .searchFriend_cGrPcVB1 > i { color: #DDD; }

.abyssTheme .friend_cGrPcVB1:hover,
.abyssTheme .friend_cGrPcVB1.selected  { background-color: #754242; }
.abyssTheme .friend_cGrPcVB1 .usertag { color: #DDD; }
.abyssTheme .stickyBottom_aGPcVB1 { background-color: #5b2828;border-color: #754242; }
.abyssTheme .note_gPcVB1 { color: #CCACAC; }

.abyssTheme .topPart_friendsMMVB1 #groupSettingsBtn { background-color: #a83f3f;border-color: #C47373; }
.abyssTheme .topPart_friendsMMVB1 #membersList img { outline-color: transparent; }

/* -- Loading */
.abyssTheme .skeleton {
    background: linear-gradient(to right, #E0E0E01A 8%, #E0E0E040 18%, #E0E0E059 33%);
    background-size: 1200px 100%;
}

/* ----------
 * XMAS THEME
 * ----------
 */
html.xmasTheme,
body.xmasTheme {
    background: url('../../img/themes/xmas.svg') center center / cover no-repeat;
    color: #fff;
}

.xmasTheme a { color: #F0F0F0BF; }

.xmasTheme [contentEditable=true]:empty:not(:focus):before { color: #F0F0F0BF; }

.xmasTheme .headerMain_pcverSB1 {
    background: linear-gradient(90deg, #FFFFFF4D 0%, #FFFFFF33 10%, #FFFFFF26 50%, #FFFFFF33 90%, #FFFFFF4D 100%);
    backdrop-filter: blur(20px);
    color: #fff;
}

/* -- Header */
.xmasTheme .headerMain_pcverSB1 > #search { background-color: #FFFFFF26; }
.xmasTheme .headerMain_pcverSB1 > #search .searchInput_sITPMVB1 { border-color: #F0F0F033; }

/* -- Navbar */
.xmasTheme .navBar_pcverSB1 {
    background: linear-gradient(90deg, #FFFFFF4D 0%, #FFFFFF26 100%);
    border-color: #FFFFFF33;color: #fff;
}
.xmasTheme .actions_bNBmVeB1 a { color: #fff; }

/* -- Live Search */
.xmasTheme #liveSearch { background: linear-gradient(70deg, #DF727E 0%, #B54B56 50%, #B54B56 75%, #DF727E 100%); }

.xmasTheme #liveSearch .profileLiveSearch_lSearchMVB1:hover { background: #DE8C8C40; }
.xmasTheme #liveSearch .profileLiveSearch_lSearchMVB1 #usertag { color: #F0F0F0BF; }
.xmasTheme a > .noResults_lSearchMVB1 { color: #F0F0F0BF; }

/* -- Right Menu */
.xmasTheme .trendMenu_pcverSB1 {
    background: linear-gradient(90deg, #FFFFFF26 0%, #FFFFFF4D 100%);
    backdrop-filter: blur(15px);
    color: #fff;
}
.xmasTheme .trendContainer_mDrawerMVB1 { background-color: #E0E0E01A;border-color: #F0F0F040; }
.xmasTheme .stats_tCMVB1 { color: #F0F0F0BF; }
.xmasTheme .accountPre_sRlBPcVB1 { border-color: #F0F0F040; }
.xmasTheme .accountPre_sRlBPcVB1:not([id="no-hover"]):hover { background-color: #FFFFFF1A; }
.xmasTheme .accountPre_sRlBPcVB1 > #profile > #user #usertag { color: #F0F0F0BF; }
.xmasTheme .suggestions_rlBPcVB1 > #refresh { color: #F0F0F0CC; }
.xmasTheme .legalMentions_tMpVB1, .xmasTheme .legalMentions_tMpVB1 a { color: #F0F0F0BF; }

.xmasTheme .trendMenu_pcverSB1 #hide-show .background { fill: #FFFFFF1A; }
.xmasTheme .trendMenu_pcverSB1 #hide-show .arrow { fill: #FFBBCF; }

.xmasTheme .trendMenu_pcverSB1 .hideShow-category { color: #F0F0F0BF; }

/* -- Left Menu */
.xmasTheme .topMenu_pcverSB1 {
    background: linear-gradient(90deg, #FFFFFF26 0%, #FFFFFF4D 100%);
    backdrop-filter: blur(10px);
    color: #fff;
}
.xmasTheme .others_fBmVeB1 div:hover:not([class="newChat-privateMsgPcVB1"]) { background-color: #E0E0E026; }
.xmasTheme .others_fBmVeB1 div.current { background-color: #E0E0E026; }
.xmasTheme .others_fBmVeB1 #for-you .gradient { color: #D1BC77;background-color: transparent; }
.xmasTheme .others_fBmVeB1 #for-you:not([class="current"]):hover .gradient-in { background-color: transparent;color: #fff; }
/* -- -- Filters Part */
.xmasTheme .filter_fBmVeB1 div.current { background-color: #E0E0E026; }
.xmasTheme .filter_fBmVeB1 div.current:hover { background-color: #E0E0E026; }
.xmasTheme .filter_fBmVeB1 div:not([class="foo-menu-social"]):hover { background-color: #E0E0E026; }
.xmasTheme .filter_fBmVeB1 div i[class="fi fi-sr-check-circle"] { color: #D1BC77; }

/* -- Separator */
.xmasTheme .separator_fBmVeB1, .xmasTheme .separator_sMAMVeB1 { border-color: #F0F0F040; }
.xmasTheme .topMenu_pcverSB1 .separator_fBmVeB1 { border-color: #FFFFFF33; }
.xmasTheme .linksProfile_tPProfileMVB1 #separator_lProfileMVB1 { border-color: #F0F0F080; }

/* -- Home */
.xmasTheme .mainPart_mAppMVB1 {
    background: linear-gradient(90deg, #FFFFFF40 0%, #FFFFFF33 10%, #FFFFFF26 50%, #FFFFFF33 90%, #FFFFFF40 100%);
    backdrop-filter: blur(20px);
}

/* -- Posts */
.xmasTheme .spost_mAppMVeB1 { border-color: #FFFFFF33; }
.xmasTheme .mediaSpost { border: #F0F0F040; }
.xmasTheme .countInteractions_spostMAppMVB1 { background-color: #F0F0F033; }
.xmasTheme .countInteractions_spostMAppMVB1 #separator { border-color: #E0E0E080; }
.xmasTheme .countInteractions_spostMAppMVB1 div:not([id="separator"]):hover { background-color: #f0f0f050; }

.xmasTheme .spost_sMAMVeB1 {
    background: linear-gradient(90deg, #FFFFFF40 0%, #FFFFFF33 10%, #FFFFFF26 50%, #FFFFFF33 90%, #FFFFFF40 100%);
    backdrop-filter: blur(5px);
    color: #fff;
}
.xmasTheme .topPart_sMainMVB1,
.xmasTheme .spostContent_sMainMVB1 { background: #FFF2; }

.xmasTheme .countInteractions_sMAMVeB1 { background-color: #F0F0F033;backdrop-filter: blur(5px); }
.xmasTheme .countInteractions_sMAMVeB1 #separator { border-color: #E0E0E080; }
.xmasTheme .countInteractions_sMAMVeB1 div:not([id="separator"]):hover { background-color: #f0f0f050; }
.xmasTheme .moreInteractions_sMAMVeB1 { background-color: #F0F0F033;backdrop-filter: blur(5px); }

.xmasTheme .profilePart_sMainMVB1 #usertag,
.xmasTheme .datePart_sMainMVB1,
.xmasTheme .profilePart_spostMAppMVB1 #usertag,
.xmasTheme .datePart_spostMAppMVB1 { color: #F0F0F0BF; }

.xmasTheme .tags_sMAMVeB1 div { background-color: #ffaeae40;color: #fff; }

.xmasTheme .profilePart_spostMAppMVB1 .avatar_pPSMaMvB1 { background-color: #FFFFFF33; }

.xmasTheme .countInteractions_spostMAppMVB1 #like.liked i:not([id="ghost"]),
.xmasTheme .countInteractions_sMAMVeB1 #like.liked i:not([id="ghost"]) { color: rgb(210, 53, 53); }

.xmasTheme .countInteractions_spostMAppMVB1 #like.liked:hover i:not([id="ghost"]),
.xmasTheme .countInteractions_sMAMVeB1 #like.liked:hover i:not([id="ghost"]) { color: #fff; }

.xmasTheme .profilePart_spostMAppMVB1 #username.creator { background-color: #BD6060; }

/* -- Comments */
.xmasTheme .addComBtn_sMAMVeB1 {
    background-color: #F0F0F033;
    backdrop-filter: blur(5px);
    border-color: #F0F0F050;
}

.xmasTheme .countInteractions_comMAMVeB1 { background-color: #F0F0F033;backdrop-filter: blur(5px); }
.xmasTheme .countInteractions_comMAMVeB1 #separator { border-color: #E0E0E080; }
.xmasTheme .countInteractions_comMAMVeB1 div:not([id="separator"]):hover { background-color: #f0f0f050; }

.xmasTheme #replyContainer { border-color: #F0F0F040; }
.xmasTheme #seeReplies { color: #F0F0F0BF; }

/* -- Search */
.xmasTheme .profile_searchMVB1 { border-color: #F0F0F040; }
.xmasTheme .infoPart_profileSearchMVB1 #usertag,
.xmasTheme .datePart_profileSearchMVB1 { color: #F0F0F0BF; }

.xmasTheme .aboutPart_profileSearchMVB1,
.xmasTheme .profile_searchMVB1 .bottomActions > #profile,
.xmasTheme .profile_searchMVB1 .bottomActions > div {
    background: linear-gradient(90deg, #FFFFFF40 0%, #FFFFFF33 10%, #FFFFFF26 50%, #FFFFFF33 90%, #FFFFFF40 100%);
}

.xmasTheme .profile_searchMVB1 .bottomActions > #followers > .followerTextPart_fBAMVB1 { border-color: #F0F0F050; }

/* -- Settings */
.xmasTheme .saveBtnContainer_setMain-pcVB1 { background-color: #ffffff5b;color: #fff; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings div input { background-color: #F0F0F070;color: #fff; }
.xmasTheme .updateBtn_tPSAMVB1 { background-color: #000;color: #fff; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings > div:not([class="topSection_pswdSet-accSetPcVB1"]) { border-color: #b1636380; }
.xmasTheme .topPart_setAccMVB1 > #subtitle { color: #f0f0f0BF; }

.xmasTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > div { background-color: #fff;color: #000; }
.xmasTheme .confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > #goBack:hover { background-color: #E8E8E8;border-color: #E8E8E8; }

/* -- -- Account */
.xmasTheme .updatePswd_accSetPcVB1,
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1 { background-color: #F0F0F070;backdrop-filter: blur(10px);color: #FFF; }

.xmasTheme .updatePswd_accSetPcVB1:hover,
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings div .twofaBtn_2faSetMVB1:hover { background-color: #FFF7; }

.xmasTheme .settingsPart_setMainMVB1 #AccountSettings div .warning_2faSetMVB1 { background-color: #F0F0F040;color: #F0F0F0BF; }

/* -- -- Privacy */
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .demandDataBtn_privacySetMVB1,
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings .selectBox_visSetMVB1 {
    background-color: #F0F0F070;
    backdrop-filter: blur(5px);
    color: #fff;
    border-color: #F0F0F040;
}

.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.privacy .seeOurPolicies { background-color: #E0E0E040; }

.xmasTheme .settingsPart_setMainMVB1 #AccountSettings .selectBox_visSetMVB1 select { color: #fff; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings .selectBox_visSetMVB1 #icon { color: #fff; }


/* -- -- Safety */
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.privacy div #noteInfo { color: #F0F0F0BF; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 #filters div { background-color: #F0F0F040;color: #fff; }

.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label { background-color: #c47878; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input + label::before { background-color: #ECBABA; }

.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label { background-color: #D1BC77; }
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.security .filterMsgSetting_accSetMVB1 input:checked + label::before { background-color: #ffffff; }

/* -- -- Sessions */
.xmasTheme .sessionContainer_currentSetPcVB1,
.xmasTheme .sessionContainer_othersSetPcVB1 {
    background-color: #e0e0e096;
    backdrop-filter: blur(5px);
    color: #fff;
}

.xmasTheme .sessionContainer_currentSetPcVB1 > .infosDevice #location,
.xmasTheme .sessionContainer_othersSetPcVB1 > .infosDevice #location { color: #F0F0F0BF; }
.xmasTheme .sessionContainer_currentSetPcVB1 > i,
.xmasTheme .sessionContainer_othersSetPcVB1 > i { color: #F0F0F0BF; }

.xmasTheme .sessionContainer_othersSetPcVB1 > .closeConnection { color: #fff; }
.xmasTheme .sessionContainer_othersSetPcVB1 > .closeConnection > i:hover { color: #F0F0F0BF; }

/* -- -- Appearance */
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings div > h2 { color: #F0F0F0BF; }
.xmasTheme .exclusThemes_appSetMVB1 #theme,
.xmasTheme .settingsPart_setMainMVB1 #AccountSettings.appearance .themeContainer_appSetMVB1 #theme { border-color: #976a6a; }
.xmasTheme #theme.selected { border-color: #fff; }
.xmasTheme .themeSelected { background-color: #fff;color: #000; }
.xmasTheme .exclusThemes_appSetMVB1 {
    border-color: #E0E0E080;
    background-color: #F0F0F020;
    backdrop-filter: blur(7px);
}
.xmasTheme .newInfoTag_exclusTPcVB1 { background: #FFF;color: #000; }

/* -- -- Language */
.xmasTheme .settingsPart_setMainMVB1 #LanguageSettings div #languageChoice { background-color: #ffffff7d; }
.xmasTheme .settingsPart_setMainMVB1 #LanguageSettings div { border-color: #E0E0E080; }

/* -- Friends */
.xmasTheme .topPart_friendsMMVB1 { border-color: #cd818180; }

.xmasTheme .userContainer_friendsMMVB1 { background-color: #cacaca41;backdrop-filter: blur(3px); }
.xmasTheme .userContainer_friendsMMVB1 #profilePart .usertag #usertag { color: #DDD; }
.xmasTheme .userContainer_friendsMMVB1 #actionsButtons div { background-color: #3B020280; }

.xmasTheme .privateMsg_fBmVeB1 div.current { background-color: #FFFFFF1A; }
.xmasTheme .privateMsg_fBmVeB1 > div:hover { background-color: #E0E0E01A; }
.xmasTheme #userLatest .avatar img { background-color: #E0E0E080; }

.xmasTheme .topPart_friendsMMVB1 .groupButton::before { background: none; }
.xmasTheme .topPart_friendsMMVB1 .groupButton { background-color: #493b3b80;border: 3px solid #cd818180; }

/* -- Profile */
.xmasTheme #profilePart_Profile {
    border-color: #F0F0F040;
    backdrop-filter: blur(10px);
    background: #F0F0F01A;
}
.xmasTheme .spostPart_profileMVB1 { border-color: #F0F0F040; }

.xmasTheme .profileInfo_tPProfileMVB1 #usertag,
.xmasTheme .createDate_tPProfileMVB1 { color: #F0F0F0BF; }

.xmasTheme .aboutMe_tPProfileMVB1,
.xmasTheme .topPart_profileMVB1 > #actionsButtons > div,
.xmasTheme .editProfileBtn_tPProfileMVB1 {
    background: linear-gradient(90deg, #FFFFFF40 0%, #FFFFFF33 10%, #FFFFFF26 50%, #FFFFFF33 90%, #FFFFFF40 100%);
    backdrop-filter: blur(5px);
}

.xmasTheme #followCountProfile > div { background-color: #F0F0F033;backdrop-filter: blur(5px);border-color: #FFFFFF40; }
.xmasTheme .followersCount_profileMVB1 .separator_fCPMVB1 { border-color: #FFFFFF40; }

/* -- Add */
.xmasTheme .titlePage_aSPMVB1 > h2 { color: #dfc5c5; }

.xmasTheme .searchTagSpost_aSPMVB1 > input::placeholder,
.xmasTheme .textSpost_aSPMVB1 > textarea::placeholder,
.xmasTheme .titleSpost_aSPMVB1 > input::placeholder { color: #FFFFFFBF; }

.xmasTheme .textSpost_aSPMVB1 > div[contentEditable="true"],
.xmasTheme .mediaSpost_aSPMVB1 .chooseFile_mSMVB1,
.xmasTheme .searchTagSpost_aSPMVB1 > input, 
.xmasTheme .titleSpost_aSPMVB1 > input {
    color: #ffffffca;
    background-color: #c4787880;
    border-color: #ECBABA80;
    backdrop-filter: blur(7px);
}

.xmasTheme .TextareaProtectValues { background-color: transparent; }
.xmasTheme .emojiPicker_textSpost {
    background-color: #F0F0F040;
    color: #F0F0F0BF;
}

.xmasTheme .tagsSpost_aSPMVB1 div {
    background-color: #c4787880;
    border-color: #ECBABA80;
    color: #fff;
    backdrop-filter: blur(7px);
}
.xmasTheme .tagsSpost_aSPMVB1 div:hover,
.xmasTheme .tagsSpost_aSPMVB1 div.selected {
    background-color: #c47878;
    border-color: #ECBABA;
    color: #fff;
}

.xmasTheme .separator_addSpost { border-color: #F0F0F040; }

.xmasTheme .options_aSPPcVB1 {
    background-color: #c4787880;
    backdrop-filter: blur(7px);
}
.xmasTheme .options_aSPPcVB1 #betaContainer { background: #D1BC77; }

.xmasTheme .options_aSPPcVB1 input + label { background-color: #c47878; }
.xmasTheme .options_aSPPcVB1 input + label::before { background-color: #ECBABA; }

.xmasTheme .options_aSPPcVB1 input:checked + label { background-color: #D1BC77; }
.xmasTheme .options_aSPPcVB1 input:checked + label::before { background-color: #ffffff; }

.xmasTheme .buttonsAction_aSPMVB1 div { color: #000; }
.xmasTheme .buttonsAction_aSPMVB1 #infoPosting { color: #DDD; }
.xmasTheme .buttonsAction_aSPMVB1 #infoPosting a { color: #FFF; }

/* -- Menu */
.xmasTheme .menu-wrapper {
    background: linear-gradient(70deg, #d53d3d 0%, #FB8585 100%);
    border-color: #ECA1A1;
    color: #f0f0f0;
}
.xmasTheme .actionsPart_spostMMVB1 div:hover,
.xmasTheme .actionsPart_moreFriendsMMVB1 div:hover,
.xmasTheme .actionsPart_actionsMenuMMVB1 div:hover { background-color: #b5323280; }

.confirmationLogout_menuMVB1 .actionButtons_menuMVB1 > #logOut:hover { background-color: #F0F0F080; }

.xmasTheme #settingsMenu .menu-wrapper,
.xmasTheme #logoutMenu .menu-wrapper { background: linear-gradient(70deg, #d53d3d 0%, #FB8585 100%);border-color: #ECA1A1; }
.xmasTheme .settings_menuMVB1 > a { color: #F0F0F0BF; }
.xmasTheme .settings_menuMVB1 > a:hover { background: #b5323280; }

.xmasTheme #logoutMenu .menu-wrapper { color: #fff; }

.xmasTheme .topPart_notifsContainerMVB1 { border-color: #ECA1A1; }

.xmasTheme .confirmationLogout_menuMVB1 #title,
.xmasTheme .confirmationLogout_menuMVB1 #warn .ask { color: #fff; }
.xmasTheme .confirmationLogout_menuMVB1 #warn { color: #FFCDCD; }

.xmasTheme .topNav_pickerPcVB1,
.xmasTheme .lateralNav_pickerPcVB1 { background-color: #F0F0F040;border-color: #f0f0f050; }

.xmasTheme .selector_topNavPicker { background-color: #C47373;color: #fff; }

.xmasTheme .elements_pickerPcVB1 .category { color: #fff; }
.xmasTheme .elements_pickerPcVB1 .copyright { color: #F0F0F0BF; }

/* -- Chat */
.xmasTheme .bottomBarActions_chatFMVB1 {
    border-color: #F0F0F040;
    background: linear-gradient(120deg, #c15d5d 0%, #933939);
    margin-left: -15px;
    width: calc(100% - 335px);
    padding-left: 5px;
}
.xmasTheme .bottomBarActions_chatFMVB1 div { background-color: #DDDDDD30; }
.xmasTheme .bottomBarActions_chatFMVB1 div:not(.textArea_bBAChatMVB1):hover { background-color: #DDDDDD70; }

.xmasTheme .message_pChatFMMVB1 { border-color: #F0F0F040; }
.xmasTheme .message_pChatFMMVB1:hover { background-color: #f0f0f030; }
.xmasTheme .profilePart_msgPCMMVB1 #usertag,
.xmasTheme .datePart_msgPCMMVB1 { color: #F0F0F0BF; }
.xmasTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div { background-color: #3B020280; }
.xmasTheme .message_pChatFMMVB1 .controls_msgPCMMVB1 > div:hover { background-color: #6b2121; }

.xmasTheme .editReply_chatMMVB1,
.xmasTheme .typingIndicator_chatMMVB1 {
    background: linear-gradient(120deg, #c15d5d 0%, #933939);
    border-color: #F0F0F040;
}

.xmasTheme .replyContainer_msgPCMMVB1 { background: linear-gradient(40deg, #d57b7b 0%, #b53a3a 100%); }

/* -- RED */
.xmasTheme .actionsPart_spostMMVB1 #delete, .xmasTheme .actionsPart_spostMMVB1 #report,
.xmasTheme .actionsPart_moreFriendsMMVB1 #remove, .xmasTheme .actionsPart_moreFriendsMMVB1 #block  { color: #ffd6d6; }

.xmasTheme .streakInfo_containerMVB1 { background: linear-gradient(70deg, #DF727E 0%, #B54B56 50%, #B54B56 75%, #DF727E 100%); }

/* -- New modals colors */
.xmasTheme .modal .newColors, .xmasTheme #createGroup .newColors { background: linear-gradient(70deg, #FFB4BD 0%, #DF7681 50%, #E8929B 75%, #DF727E 100%); }

/* -- Modals */
.xmasTheme .topPart_fSLMPcB1 h1 { color: #fff; }
.xmasTheme .actionsButtons_adventPcVB1 > div { background-color: #f55; }

/* -- -- Group */
.xmasTheme .topPart_groupPcVB1 > h1 { color: #fff; }
.xmasTheme .topPart_groupPcVB1 > h2 { color: #F0F0F0BF; }

.xmasTheme .addPicture_gPcVB1 {
    border-color: #F0F0F080;
    color: #F0F0F080;
}
.xmasTheme .addPicture_gPcVB1:hover {
    border-color: #F0F0F040;
    color: #F0F0F040;
}

.xmasTheme .addPicture_gPcVB1 #addIcon {
    background: #e6aeae;
    color: #c46363;
}

.xmasTheme .groupName_gPcVB1 > input {
    background: #F0F0F01A;
    border-color: #F0F0F040;
    color: #fff;
}

.xmasTheme .actions_gPcVB1 > div { background-color: #ff7575; }
.xmasTheme .actions_gPcVB1 > div:hover { background-color: #ffa0a0; }
.xmasTheme .actions_gPcVB1 > #create::before { background: #F0F0F033; }
.xmasTheme .actions_gPcVB1 > #cancel, .xmasTheme .actions_gPcVB1 > #go-back { border-color: #F0F0F033; }

.xmasTheme .searchFriend_cGrPcVB1 {
    background-color: #F0F0F026;
    border-color: #F0F0F040;
}
.xmasTheme .searchFriend_cGrPcVB1 > i { color: #F0F0F0BF; }

.xmasTheme .friend_cGrPcVB1:hover,
.xmasTheme .friend_cGrPcVB1.selected  { background-color: #F0F0F033; }
.xmasTheme .friend_cGrPcVB1 .usertag { color: #F0F0F0BF; }
.xmasTheme .stickyBottom_aGPcVB1 { background: linear-gradient(70deg, #FFB4BD -5%, #DF7681 75%, #E88C96 100%);border-color: #F0F0F040; }
.xmasTheme .note_gPcVB1 { color: #F0F0F0BF; }

.xmasTheme .topPart_friendsMMVB1 #groupSettingsBtn { background-color: #a83f3f;border-color: #C47373; }
.xmasTheme .topPart_friendsMMVB1 #membersList img { outline-color: transparent; }

/* -- Loading */
.xmasTheme .skeleton {
    background: linear-gradient(to right, #E0E0E01A 8%, #E0E0E040 18%, #E0E0E059 33%);
    background-size: 1200px 100%;
}